<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-subheader class="headline primary--text">Adcionar Novo Projeto</v-subheader>
                <p-breadcrumbs page="profile.control-panel.project.new" />
            </v-col>
        </v-row>
        <ProjectList />
    </v-container>
</template>

<script>

import ProjectList from '../../../components/author/control-panel/ProjectList';
//import { mapState, mapActions } from 'vuex';
import {HOME} from '../../../routes';
export default {
    created() {
        if (this.$user.isGuest) {
            this.$router.push({path: HOME}); //TODO check auth with globar guard
            return;
        }
        //this.fetchDetail();
    },
    
    components: {
        ProjectList,
    },
    /*computed: {
        userId() {
            return this.$user.info.id;
        },
         ...mapState('author/dashboard', ['author', 'loading'])
    },
    methods: {
        ...mapActions('author/dashboard', ['detail']),
        fetchDetail() {
            this.detail({ id: this.userId});
        }
    }, */  
}
</script>